























































































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  getComponent,
} from '@/utils/helpers';
import userTrackedIssuesModule from '@/store/modules/userTrackedIssuesModule';
import sprintDetailsModule from '@/store/modules/sprintDetailsModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    UserSettings: () => getComponent('common/UserSettings'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
  },
})
export default class IssueStatus extends Vue {
    addIssuePopupShowing = false

    dataLoading = false

    issueDescription = ''

    issueTitle = ''

    issueType = ''

    pageAffected = ''

	isLoadingUserIssues = false

	async created() {
	  this.isLoadingUserIssues = true;
	  await userTrackedIssuesModule.getIssues();
	  this.isLoadingUserIssues = false;
	  await sprintDetailsModule.getSprintDetails();
	}

	get getCurrentName(): string {
	  return getNameByEmail(accountModule.user.email);
	}

	get sprintDetails(): any {
	  return sprintDetailsModule.sprintDetails;
	}

	get userIssues(): any {
	  return userTrackedIssuesModule.userIssues;
	}

	toggle_row(id) {
	  $(`#extra_${id}`).slideToggle();
	}

	async submitNewTicket() {
	  //   #  PERFORMANCE, DATA_ISSUE, FUNCTIONALITY_NOT_WORKING, REQUEST, CURRENT_SPRINT or BUG
	  let page_formatted = 'ENTIRE_APPLICATION';
	  if (this.pageAffected == 'My tasqs') {
	    page_formatted = 'MY_TASQS';
	  } else if (this.pageAffected == 'Workflow') {
	    page_formatted = 'WORKFLOW';
	  } else if (this.pageAffected == 'Operation') {
	    page_formatted = 'OPS';
	  }

	  // 'Bug', 'Load times', 'Data issue', 'Functionality not working', 'Request'
	  let issue_type = 'BUG';
	  if (this.issueType == 'Load time') {
	    issue_type = 'PERFORMANCE';
	  } else if (this.issueType == 'Data issue') {
	    issue_type = 'DATA_ISSUE';
	  } else if (this.issueType == 'Functionality not working') {
	    issue_type = 'FUNCTIONALITY_NOT_WORKING';
	  } else if (this.issueType == 'Request') {
	    issue_type = 'REQUEST';
	  }

	  this.dataLoading = true;

	  await userTrackedIssuesModule.postNewIssue({
	    username: accountModule.user.email,
	    description: this.issueDescription,
	    ticket_title: this.issueTitle,
	    page: page_formatted,
	    issue_type,
	  });

	  this.addIssuePopupShowing = false;
	  userTrackedIssuesModule.getIssues();
	  //   location.reload()
	  this.dataLoading = false;
	}
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
